import '@css/app.pcss';

// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}


ready(() => {
  /* Do things after DOM has fully loaded */

  // Navigation 
  const toggleMenu = document.querySelector('.hamburger');
  const menu = document.querySelector('.main-menu');
  const siteBody = document.getElementsByTagName("BODY")[0];

  //--------------------------------------------------------------- Navigation

  /* When you click on the button, we call a function that will get the current value of the "aria-expanded" attribute, and invert it. It will do the same for the "hidden" attribute on the menu. When the menu is visible, we'll focus the first link so it gets properly announced. */

  toggleMenu.addEventListener('click', function() {
    // The JSON.parse function helps us convert the attribute from a string to a real boolean
    const open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));

    // whatever the value of open is set it to the opposite
    toggleMenu.setAttribute('aria-expanded', !open);

    // whatever the value of the html element 'hidden' on menu is set it to the opposite
    menu.hidden = !menu.hidden;

    //var hamburger = document.querySelector(".hamburger");
    toggleMenu.classList.toggle("is-active");

    // toggle the visibility of the mobile menu
    menu.classList.toggle("navigation-active-mobile");

    // lock the header on mobile
    siteBody.classList.toggle("navigation-active-lock-header");

    // lock the body so it doesn't scroll
    siteBody.classList.toggle("lock-scroll-mobile");

    // if the menu isn't hidden focus on the first menu link
    if (!menu.hidden) {
      menu.querySelector('a').focus();
    }

  });


  // Drop down menus on navigation

  var menuItems = document.querySelectorAll('li.menu-item-has-children');

  Array.prototype.forEach.call(menuItems, function(el, i) {

    var activatingA = el.querySelector('a');
    var btn = '<button class="submenu-button-icon"><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span></span></button>';
    activatingA.insertAdjacentHTML('afterend', btn);

    el.querySelector('button').addEventListener("click", function(event) {

      if (this.parentNode.classList.contains("submenu-open")) {

        //console.log('close the sub menu!');

        this.parentNode.classList.remove("submenu-open");
        this.parentNode.classList.add("submenu-closed");

        this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");


      } else {

        // console.log('open the sub menu!');

        this.parentNode.classList.remove("submenu-closed");
        this.parentNode.classList.add("submenu-open");

        this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
        this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");

      }

      event.preventDefault();
    });

  });


  // Lazy load collection grid items


  /** First we get all the non-loaded image elements **/
  var lazyLoadImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  function observerCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        let lazyImage = entry.target;
        lazyImage.src = lazyImage.dataset.src;
        lazyImage.classList.remove("lazy");
        /*observerCallback.unobserve(lazyImage);*/
      }
    });
  }

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  lazyLoadImages.forEach((el) => observer.observe(el));

  // Load Dynamic Data using the Fetch API

  function loadDynamicModalData(dynamicData, dynamicModal, hidePrint) {

    let dataHolder = dynamicModal.querySelector('.dynamic-modal-data-holder');

    let modalPreloader = dynamicModal.querySelector('.modal-preloader');

    modalPreloader.classList.remove("modal-preloader-hidden");

    fetch(dynamicData).then(function(response) {
      // The API call was successful!
      return response.text();
    }).then(function(html) {

      // Hide the preloader 
      modalPreloader.classList.add("modal-preloader-hidden");

      // Use appendChild to only insert the content of the dynamic-data-content div 
      // Convert the HTML string into a document object
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, 'text/html');
      var parsedDynamicData = doc.getElementById("dynamic-data-content");

      dataHolder.appendChild(parsedDynamicData);

      if (hidePrint == true) {
        //alert('add data attribute to button to hide on toggle!');
        // get element by ID print-folio-toggle
        let hidePrintButtonToggle = document.getElementById('print-folio-toggle');
        // Add data attribute to the above data-hide-print-on-toggle="true"
        hidePrintButtonToggle.setAttribute('data-hide-print-on-toggle', 'true')
      }
      // Initiate Zoom Images in the Modal
      let zoomElements = document.querySelectorAll('.magnify-image');
      attachZoomListeners(zoomElements);

      // Initiate Variant Selector in the Modal
      let variantThumbs = document.querySelectorAll('.variant-thumb');
      let variantImageItems = document.querySelectorAll('.collection-print-variant-image');
      attachVariantSwitchers(variantThumbs, variantImageItems);

      // Initiate the wishlist toggle buttons in the Modal
      let modalWishListToggle = document.querySelectorAll('.modal-wishlist-toggle');
      attachWishListToggles(modalWishListToggle);

      // Initate the how it works fabric toggle section
      let howItWorksFabricsToggles = document.querySelectorAll('.how-it-works-fabric-toggle');
      let howItWorksFabricsToggleTargets = document.querySelectorAll('.suggested-fabrics-how-it-works');
      attachHowItWorksFabricsToggles(howItWorksFabricsToggles, howItWorksFabricsToggleTargets);


    }).catch(function(err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });

  }

  // Dynamic Work Modal

  function loadDynamicWorkModalData(dynamicData, dynamicModal) {

    let dataHolder = dynamicModal.querySelector('.dynamic-modal-data-holder');

    let modalPreloader = dynamicModal.querySelector('.modal-preloader');

    modalPreloader.classList.remove("modal-preloader-hidden");

    fetch(dynamicData).then(function(response) {
      // The API call was successful!
      return response.text();
    }).then(function(html) {

      // Hide the preloader 
      modalPreloader.classList.add("modal-preloader-hidden");

      // Use appendChild to only insert the content of the dynamic-data-content div 
      // Convert the HTML string into a document object
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, 'text/html');
      var parsedDynamicData = doc.getElementById("dynamic-data-content");

      dataHolder.appendChild(parsedDynamicData);

    }).catch(function(err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });

  }


  // -------------------------------------------------------- Accessible modals


  // Open modals
  document.querySelectorAll('[data-modal]').forEach(item => {

    item.addEventListener('click', event => {

      let targetLink = item.getAttribute('data-modal');

      let targetModal = document.getElementById(targetLink);

      let hidePrintOnToggle = false;

      openModal(targetModal);

      if (item.hasAttribute("data-collection-item")) {
        let targetData = item.getAttribute('data-collection-item');

        if (item.hasAttribute("data-hide-print-on-toggle")) {

          hidePrintOnToggle = true;

        }

        loadDynamicModalData(targetData, targetModal, hidePrintOnToggle);

      } else if (item.hasAttribute("data-our-work-item")) {

        let targetData = item.getAttribute('data-our-work-item');

        loadDynamicWorkModalData(targetData, targetModal);

      }


      event.preventDefault();

    })

  })


  function openModal(targetModal) {

    targetModal.classList.add("modal-visible");

    // lock the body so it doesn't scroll
    siteBody.classList.add("lock-body-modal-open");
  }

  // Close modals

  function closeModals() {

    // lock the body so it doesn't scroll
    siteBody.classList.remove("lock-body-modal-open");

    let targetModals = document.querySelectorAll('.modal');

    targetModals.forEach(function(el) {
      el.classList.remove("modal-visible");
    });


    /* This function removes dynamically loaded content from any open modals thus stopping any media thats playing and clearing the dynamic modal ready for the next load */
    let dataHoldersToClear = document.querySelectorAll('.dynamic-modal-data-holder');

    dataHoldersToClear.forEach(function(el) {
      el.innerHTML = '';
    });



  }

  document.querySelectorAll('[data-modal-close-trigger]').forEach(item => {

    item.addEventListener('click', event => {

      closeModals();

      return false;

    })

  })

  // close modals with esc key
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeModals();
    }
  });

  // Attach how it works toggle for suggested fabrics

  function attachHowItWorksFabricsToggles(toggleBtns, toggleTargets) {
    toggleBtns.forEach(item => {
      item.addEventListener('click', (e) => {

        toggleBtns.forEach((e) => {
          e.classList.toggle('how-it-works-fabric-toggle-toggled');
        });

        toggleTargets.forEach((e) => {
          e.classList.toggle('suggested-fabrics-how-it-works-hidden');
        });

      });
    });
  }

  let howItWorksFabricsToggles = document.querySelectorAll('.how-it-works-fabric-toggle');
  let howItWorksFabricsToggleTargets = document.querySelectorAll('.suggested-fabrics-how-it-works');

  attachHowItWorksFabricsToggles(howItWorksFabricsToggles, howItWorksFabricsToggleTargets);


  // Attach toggle for fabric descriptions

  function attachFabricDescriptionToggles(toggleBtns) {

    toggleBtns.forEach(item => {

      item.addEventListener('click', (e) => {

        item.classList.toggle('fabric-description-button-toggled');

        item.nextElementSibling.classList.toggle('fabric-description-hidden');




      });
    });
  }

  let fabricDescriptionToggles = document.querySelectorAll('.fabric-description-button');

  attachFabricDescriptionToggles(fabricDescriptionToggles);






  // Load print into modal with Fetch

  // Zoom and pan prints



  // Attach Event Listeners to Zoom Elements

  function attachZoomListeners(magnifyItems) {

    magnifyItems.forEach(zoomElement => {

      zoomElement.addEventListener('mousemove', (e) => {

        var zoomer = e.currentTarget;

        let offsetHorizontal = e.offsetX;
        let offsetVertical = e.offsetY;

        let x = offsetHorizontal / zoomer.offsetWidth * 100
        let y = offsetVertical / zoomer.offsetHeight * 100

        zoomer.style.backgroundPosition = x + '% ' + y + '%';

      });

    });

  }

  let zoomElements = document.querySelectorAll('.magnify-image');
  attachZoomListeners(zoomElements);

  // Display variants when thumbnails are clicked

  function attachVariantSwitchers(thumbs, variantImages) {

    thumbs.forEach(variantThumb => {

      variantThumb.addEventListener('click', (event) => {

        // Hide any active collection images
        variantImages.forEach((e) => {
          e.classList.remove('collection-print-variant-image-active');
        });

        // reveal the target variant image

        let targetVariantImage = variantThumb.getAttribute('data-variant-target');

        let targetImage = document.getElementById(targetVariantImage);

        targetImage.classList.add('collection-print-variant-image-active');

        // Remove the active class from all thumbs
        thumbs.forEach((e) => {
          e.classList.remove('active-variant');
        });

        // Add the active class to this thumb
        variantThumb.classList.add('active-variant');

        // Let's pass this variant-thumbs data attribute to the custom field in our wishlist form
        let customPrintVariantOption = variantThumb.getAttribute('data-print-variant');
        let wishListCustomPrintVariantOptionField = document.getElementById('wishlist-print-variant-option-field');

        if (wishListCustomPrintVariantOptionField != null) {
          wishListCustomPrintVariantOptionField.value = customPrintVariantOption;
        }

        // Lets add the variant-thumbs data attribute to the enquiry button url
        let printEnquiryButton = document.getElementById('print-enquiry-button');

        if (printEnquiryButton != null) {
          let basePrintEnquiryUrl = printEnquiryButton.getAttribute('data-enquiry-url');
          printEnquiryButton.setAttribute("href", `${basePrintEnquiryUrl}&variant=${customPrintVariantOption}`);
        }

      });

    });

  }

  let variantThumbs = document.querySelectorAll('.variant-thumb');
  let variantImageItems = document.querySelectorAll('.collection-print-variant-image');
  attachVariantSwitchers(variantThumbs, variantImageItems);


  // Wishlist Toggles



  function submitWishlistToggleForm(targetFormID, targetWlToggleBtn) {

    let $form = targetFormID;
    let data = new FormData($form);

    fetch('/', {
      method: 'post',
      body: data,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then(function(response) {
      response.json().then(function(data) {
        console.log(data);
        //console.log(data.action);
        //console.log(data.items[0].action);

        // work out of the item was added or removed based on the response

        let itemToggleStatus = data.items[0].action;

        updateCartCount(itemToggleStatus);

        updateToggleBtn(targetWlToggleBtn);

        if (targetWlToggleBtn.hasAttribute("data-modal-print")) {

          let relatedPrintToggle = targetWlToggleBtn.getAttribute('data-modal-print');
          let relatedPrintToggleId = document.getElementById(relatedPrintToggle);

          if (relatedPrintToggleId != null) {
            updateToggleBtn(relatedPrintToggleId);
          }

        }


        if (targetWlToggleBtn.hasAttribute("data-hide-print-on-toggle")) {

          let gridItemTarget = targetWlToggleBtn.getAttribute('data-hide-print-on-toggle-target');
          let gridItemTargetId = document.getElementById(gridItemTarget);

          if (gridItemTargetId != null) {
            gridItemTargetId.classList.toggle('grid-item-hidden');
          }

        }

      }).catch(function(err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
    });

  }

  function updateToggleBtn(targetBtn) {

    let targetBtnTitle = targetBtn.querySelector('.withlist-toggle-title');

    if (targetBtn.classList.contains('portfolio-shortcut-icon')) {
      // Item has been added to the portfolio
      targetBtn.classList.remove('portfolio-shortcut-icon');
      targetBtn.classList.add('portfolio-remove-icon');
      targetBtnTitle.innerHTML = 'Remove from my Portfolio';
      targetBtn.setAttribute('aria-label', 'Remove from my Portfolio');
    } else {
      // Item has been removed from the portfolio
      targetBtn.classList.remove('portfolio-remove-icon');
      targetBtn.classList.add('portfolio-shortcut-icon');
      targetBtnTitle.innerHTML = 'Add to my Portfolio';
      targetBtn.setAttribute('aria-label', 'Add to my Portfolio');
    }



  }

  function updateCartCount(status) {

    document.querySelectorAll('.portfolio-count-icon').forEach(function(portfolioIconCount) {

      // get the starting number via a data attribute
      let iconCount = portfolioIconCount.getAttribute('data-quote-cart-count');
      // convert it to a number so we can add or subtract from it
      let iconCountNumber = Number(iconCount);

      if (status == 'added') {

        console.log('item was added');
        let newCount = iconCountNumber + 1;

        portfolioIconCount.innerText = newCount;
        // also set the attribute so it can be used next time 
        portfolioIconCount.setAttribute('data-quote-cart-count', newCount);

        if (newCount > 0) {
          portfolioIconCount.classList.add('portfolio-count-icon-visible');
        } else {
          portfolioIconCount.classList.remove('portfolio-count-icon-visible');
        }

      } else if (status == 'removed') {

        console.log('item was removed');
        let newCount = iconCountNumber - 1;

        portfolioIconCount.innerText = newCount;
        // also set the attribute so it can be used next time 
        portfolioIconCount.setAttribute('data-quote-cart-count', newCount);

        if (newCount > 0) {
          portfolioIconCount.classList.add('portfolio-count-icon-visible');
        } else {
          portfolioIconCount.classList.remove('portfolio-count-icon-visible');
        }

      }



    });

  }

  function attachWishListToggles(toggles) {

    const wishListFormItemID = document.getElementById('wishlist-toggle-form');
    let wishListElementIdField = wishListFormItemID.elementId;

    toggles.forEach(toggle => {

      toggle.addEventListener('click', (event) => {

        // Get the entry.id from a data attribute
        let dynamicDataAttr = toggle.getAttribute('data-print-id');

        // Set the print you want to submit with the form
        wishListElementIdField.value = dynamicDataAttr;

        // Submit the form via Fetch
        submitWishlistToggleForm(wishListFormItemID, toggle);

        // Micro animation on Portfolio icons
        portfolioHint();

        event.preventDefault();

      });

    });
  }


  let wishListToggles = document.querySelectorAll('.wishlist-toggle');
  if (wishListToggles.length !== 0) {
    attachWishListToggles(wishListToggles);
  }

  function portfolioHint() {

    document.querySelectorAll('.my-account-link, .my-portfolio-btn').forEach(item => {
      item.classList.add('portfolio-micro-hint');

      setTimeout(() => {
        item.classList.remove('portfolio-micro-hint');
      }, "3000");

    })




  }

  // Sticky Portfolio Button

  let stickySidebarMenu = document.querySelector('.sticky-sidebar-element');
  let stickyFolioBtnTrigger = document.querySelector('.sticky-sidebar-element-trigger');

  function handleIntersection(entries) {

    entries.map((entry) => {

      if (entry.isIntersecting) {

        entry.target.classList.add('visible');
        stickySidebarMenu.classList.remove('sticky-sidebar-element-visible');

      } else {

        entry.target.classList.remove('visible');
        stickySidebarMenu.classList.add('sticky-sidebar-element-visible');

      }
    });
  }

  const stickyObserver = new IntersectionObserver(handleIntersection);

  if (stickyFolioBtnTrigger != null) {

    stickyObserver.observe(stickyFolioBtnTrigger);

  }

  // Collection category list toggle mobile

  let collectionCategoryToggle = document.getElementById('collection-category-toggle');
  let collectionCategoryList = document.getElementById('collection-category-list');

  if (collectionCategoryToggle !== null) {

    collectionCategoryToggle.addEventListener('click', (event) => {

      collectionCategoryToggle.classList.toggle('collection-category-filter-open');

      collectionCategoryList.classList.toggle('collection-category-list-open');

    });

  }
  
  // Collection child category toggles
  
  const childCategoryToggles = document.querySelectorAll('.child-category-toggle-menu'); 
  const childCategoryMenus = document.querySelectorAll('.child-category-menu'); 
  
  childCategoryToggles.forEach(toggle => {
    
    toggle.addEventListener('click', (event) => {
      
      toggle.classList.toggle("child-category-toggle-menu-open");
      let targetChildCatMenuID = toggle.getAttribute('data-target-menu');
      let targetChildCatMenu = document.getElementById(targetChildCatMenuID);
      
      targetChildCatMenu.hidden = !targetChildCatMenu.hidden; 
      // Do stuff to other elements
      
    });
    
  });


}); // END DOC READY